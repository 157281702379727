<template>
  <v-row v-if="true" dense class="justify-center">
    <v-col cols="12">
      <v-card class="mb-4 primary darken-4" dark>
        <v-card-title>
          <v-btn icon @click="$router.replace({ name: 'learn' })">
            <v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <div class="text-right">
            <div class="title">{{ $route.params.subject }}</div>
          </div>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="6" md="3" v-for="item in items" :key="item.id">
      <v-card
        outlined
        @click="handleClick(item)"
        height="100"
        class="justify-center align-center d-flex flex-column"
      >
        <v-card-title class="body-2">
          {{ item.title }}
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="items.length < 1">
      <v-card-title class="justify-center">
        <v-progress-linear indeterminate class="mb-6"></v-progress-linear>
        {{ $lang("PleaseWait") }}
      </v-card-title>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";

export default {
  components: {},
  name: "learn-categories",
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    "authUser.id"(val) {},
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        name: "learn-questions",
        params: { subject: this.$route.params.subject, category: item.title },
      });
    },
    handleRemove(item) {},

    fetchItems() {
      return axios.get(`learn/${this.$route.params.subject}`).then((res) => {
        this.items = res.data.data;
      });
    },
  },
  mounted() {
    document.title = `${this.$route.params.subject} | vikarn.com`;
    this.fetchItems();
  },
};
</script>

